const DestinationLegendBar = () => {
    return (
    <div className="legend-bar">
        <div className="legend-item">
          <span className="color-box" style={{ backgroundColor: 'blue' }}></span> Gov Anchorage
        </div>
        <div className="legend-item">
          <span className="color-box" style={{ backgroundColor: 'rgb(255, 145, 0)' }}></span> Public Dock
        </div>
        <div className="legend-item">
          <span className="color-box" style={{ backgroundColor: 'rgb(218, 38, 237)' }}></span> Sporty Anchorage
        </div>
    </div>
    );
  };

  export default DestinationLegendBar;