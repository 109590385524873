
const dockArray = [
    {
        name: "Spanish Hills Dock",
        coordinates: {
            latitude: 48.9947,
            longitude: -123.5844,
        },
        type: "dock",
        features: {
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        boatSpots: 4, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Retreat Cove Dock",
        coordinates: {
            latitude: 48.9401,
            longitude: -123.5013,
        },
        type: "dock",
        features: {
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        boatSpots: 6, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Montague Harbour Dock",
        coordinates: {
            latitude: 48.8924,
            longitude: -123.3918,        
        },
        type: "dock",
        features: {
            hiking: true,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        boatSpots: 6, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Sturdies Bay Dock",
        coordinates: {
            latitude: 48.8767,
            longitude: -123.3155,        
        },
        type: "dock",
        features: {
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        boatSpots: 6, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Gossip Island Dock",
        coordinates: {
            latitude: 48.8887,
            longitude: -123.3232,     
        },
        type: "dock",
        features: {
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        boatSpots: 6, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Miners Bay Dock",
        coordinates: {
            latitude: 48.8524,
            longitude: -123.3020,    
        },
        type: "dock",
        features: {
            hiking: false,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        boatSpots: 8, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Horton Bay Dock",
        coordinates: {
            latitude: 48.8250,
            longitude: -123.2444,   
        },
        type: "dock",
        features: {
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        boatSpots: 12, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Anson Road Dock",
        coordinates: {
            latitude: 48.8278,
            longitude: -123.2509,  
        },
        type: "dock",
        features: {
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        boatSpots: 12, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Port Washington Dock",
        coordinates: {
            latitude: 48.8129,
            longitude: -123.3207,  
        },
        type: "dock",
        features: {
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        boatSpots: 10, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Hope Bay Dock",
        coordinates: {
            latitude: 48.8033,
            longitude: -123.2754, 
        },
        type: "dock",
        features: {
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        boatSpots: 8, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Port Browning Dock",
        coordinates: {
            latitude: 48.7769,
            longitude: -123.2744,
        },
        type: "dock",
        features: {
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        boatSpots: 6, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Lyall Harbour Dock",
        coordinates: {            
            latitude: 48.7979,
            longitude: -123.1997,
        },
        type: "dock",
        features: {
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        boatSpots: 6, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Piers Island Dock",
        coordinates: {  
            latitude: 48.7008,
            longitude: -123.4115,
        },
        type: "dock",
        features: {
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        boatSpots: 10, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Swartz Bay Dock",
        coordinates: {  
            latitude: 48.6878,
            longitude: -123.4073,
        },
        type: "dock",
        features: {
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        boatSpots: 12, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Kanaka Dock",
        coordinates: {  
            latitude: 48.8539,
            longitude: -123.4979,
        },
        type: "dock",
        features: {
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        boatSpots: 8, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Musgrave Landing Dock",
        coordinates: {  
            latitude: 48.7488,
            longitude: -123.5491,
        },
        type: "dock",
        features: {
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        boatSpots: 8, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Granville Island Dock",
        coordinates: {  
            latitude: 49.2730,
            longitude: -123.1345,
        },
        type: "dock",
        features: {
            hiking: false,
            camping: false,
            beaches: false,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        boatSpots: 8, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Coal Harbour Dock",
        coordinates: {
            latitude: 49.2907,
            longitude: -123.1210,  
        },
        type: "dock",
        features: {
            hiking: false,
            camping: false,
            beaches: false,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'high', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        boatSpots: 14, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "St. Roch Dock",
        coordinates: {
            latitude: 49.3091,
            longitude: -123.0801,  
        },
        type: "dock",
        features: {
            hiking: false,
            camping: false,
            beaches: false,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        boatSpots: 10, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Horshoe Bay Dock",
        coordinates: {
            latitude: 49.3759,
            longitude: -123.2744, 
        },
        type: "dock",
        features: {
            hiking: false,
            camping: false,
            beaches: false,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        boatSpots: 5, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Snug Cove Dock",
        coordinates: {
            latitude: 49.3788,
            longitude: -123.3302,
        },
        type: "dock",
        features: {
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        boatSpots: 5, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "West Bay Dock",
        coordinates: {
            latitude: 49.4598,
            longitude: -123.4176,
        },
        type: "dock",
        features: {
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        boatSpots: 4, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Plumper Cove Dock",
        coordinates: {
            latitude: 49.4042,
            longitude: -123.4721,
        },
        type: "dock",
        features: {
            hiking: true,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'high', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        boatSpots: 16, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Gibsons Dock",
        coordinates: {
            latitude: 49.4011,
            longitude: -123.5041,
        },
        type: "dock",
        features: {
            hiking: true,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        boatSpots: 10, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Chatterbox Falls Dock",
        coordinates: {
            latitude: 50.2043,
            longitude: -123.7692,
        },
        type: "dock",
        features: {
            hiking: true,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'high', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        boatSpots: 18, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Nanaimo Port Authority Dock",
        coordinates: {
            latitude: 49.1677,
            longitude: -123.9344,
        },
        type: "dock",
        features: {
            hiking: false,
            camping: false,
            beaches: false,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        boatSpots: 6, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Rocky Point Dock",
        coordinates: {
            latitude: 49.2823,
            longitude: -122.8505,
        },
        type: "dock",
        features: {
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: "Short term only",
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        boatSpots: 8, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Indian River Dock",
        coordinates: {
            latitude: 49.3418,
            longitude: -122.9208,
        },
        type: "dock",
        features: {
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: "Short term only",
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        boatSpots: 4, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Deep Cove Dock",
        coordinates: {
            latitude: 49.3268,
            longitude: -122.9473,
        },
        type: "dock",
        features: {
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: "Short term only",
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        boatSpots: 8, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Cates Park Dock",
        coordinates: {
            latitude: 49.3014,
            longitude: -122.9601,
        },
        type: "dock",
        features: {
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: "Short term only",
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        boatSpots: 8, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Degnen Bay Dock",
        coordinates: {
            latitude: 49.1370,
            longitude: -123.7128,
        },
        type: "dock",
        features: {
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'high', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        boatSpots: 12, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Green Wharf Dock",
        coordinates: {
            latitude: 49.1421,
            longitude: -123.8046,
        },
        type: "dock",
        features: {
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: "For Mudge Island access only",
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        boatSpots: 6, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Squitty Bay Dock",
        coordinates: {
            latitude: 49.4527,
            longitude: -124.1658,
        },
        type: "dock",
        features: {
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        boatSpots: 10, // approximate number of boats that can be accommodated
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
]

export default dockArray;