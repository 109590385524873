import { FaAnchor, FaWind, FaCampground, FaHiking, FaUmbrellaBeach, FaExclamationTriangle, FaUsers, FaInfoCircle } from 'react-icons/fa'; // Icons for features
import { transformKey } from '../../functions/transformKey';

const DestinationsPopupContent = ({ selectedAnchorage }) => {
    
      
    return (
        <div style={{ width: '200px' }}>
        <h3>{selectedAnchorage.name}</h3>

            {/* Anchoring Info */}
            <div><FaAnchor /> <strong>Bottom Type:</strong> {Array.isArray(selectedAnchorage.anchoring.bottomType) ? transformKey(selectedAnchorage.anchoring.bottomType.join(', ')) : transformKey(selectedAnchorage.anchoring.bottomType)}</div>
            <div><FaAnchor /> <strong>Holding:</strong> {transformKey(selectedAnchorage.anchoring.anchorHolding) || 'Unknown'}</div>
            {selectedAnchorage.anchoring.protectedFromWinds && (
                <div><FaWind /> <strong>Protected From:</strong> {Array.isArray(selectedAnchorage.anchoring.protectedFromWinds) ? transformKey(selectedAnchorage.anchoring.protectedFromWinds.join(', ')) : 'Unknown'}</div>
            )}

            {/* Features */}
            <div><FaUmbrellaBeach /> <strong>Beaches:</strong> {selectedAnchorage.features.beaches ? 'Yes' : 'No'}</div>
            <div><FaHiking /> <strong>Hiking:</strong> {selectedAnchorage.features.hiking ? 'Yes' : 'No'}</div>
            <div><FaCampground /> <strong>Camping:</strong> {selectedAnchorage.features.camping ? 'Yes' : 'No'}</div>

            {/* Warnings */}
            {selectedAnchorage.warnings && (
                <div><FaExclamationTriangle /> <strong>Warning:</strong> {selectedAnchorage.warnings}</div>
            )}

            {/* Boat Capacity & Popularity */}
            <div><FaUsers /> <strong>Boat Capacity:</strong> {transformKey(selectedAnchorage.boatCapacity) || 'Unknown'}</div>
            <div><FaUsers /> <strong>Popularity:</strong> {transformKey(selectedAnchorage.popularity) || 'Unknown'}</div>

            {/* Score */}
            <div><FaInfoCircle /> <strong>Score:</strong> {selectedAnchorage.score}/100</div>
        
        </div>
    );
};

export default DestinationsPopupContent;
