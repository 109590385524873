import { useState, useContext, useEffect, useRef, useCallback } from 'react';
import * as React from 'react';
import ReactMapGl, { Marker, Popup, Source, Layer } from 'react-map-gl';
import { RotatingLines } from 'react-loader-spinner'
import { Context } from '../context/context.jsx';
import WeatherLayerCheckBox from '../weatherMap/weatherLayerCheckbox.jsx';
import WeatherMapUnits from '../weatherMap/weatherMapUnits.jsx';
import WeatherSettingsBtn from '../weatherMap/weatherSettingsBtn.jsx';
import ForecastSlider from '../weatherMap/forecastSlider.jsx';
import WindSpeedChart from '../weatherMap/windSpeedChart.jsx'
import { Chart as ChartJS, layouts, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { NavLink, useNavigate } from 'react-router-dom';
import * as geolib from 'geolib';
import { convertUnitsUserSettings } from '../../functions/convertUnits.js';
import SidebarPro from '../global/sidebar.jsx';
import BottomNav from '../global/bottomNav.jsx';
import Footer from '../global/footer.js';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { newWebCamArray } from '../../weatherData/newWebcams.jsx';
import SimpleCloseBtn from '../global/simpleCloseBtn.jsx';
import { transformTime } from '../../functions/transformTime.js';
import { FiWind } from "react-icons/fi";
import { GiBigWave } from "react-icons/gi";
import { BCPolygon } from '../../fishingData/BCpolygon.jsx';
import { CiCamera } from "react-icons/ci";
import { ref, uploadBytes, getDownloadURL, updateMetadata, listAll, getMetadata } from 'firebase/storage';
import { storage } from '../global/firebaseConfig.jsx';
import PhotoPreview from '../weatherMap/photoPreview.jsx';
import 'animate.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import ModalCustom from '../global/modalCustom.jsx';
import axios from 'axios';
import { format } from 'd3';
import WindSpeedWindow from '../weatherMap/windSpeedWindow.jsx';
import WeatherLegendBar from '../weatherMap/weatherLegendBar.jsx';

ChartJS.register(...registerables);

const WeatherMapBox = () => {

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  // const API_BASE_URL = 'http://localhost:3847';

  const navigate = useNavigate();
  const userLocation = useContext(Context).userLocation
  const weatherStation = useContext(Context).weatherStation
  const waveBuoy = useContext(Context).waveBuoy
  const originalWeatherData = useContext(Context).originalWeatherData
  const weatherData = useContext(Context).weatherData
  const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY;
  const mapRef = useRef(null);

  const [testData, setTestData] = useState(null);
  const [mobile, setMobile] = useState(window.innerWidth < 600);
  const [showSettingsDropdown, setShowSettingsDropdown] = useState(false);
  const [selectedWebcam, setSelectedWebcam] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [showFullSizePhoto, setShowFullSizePhoto] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [photoArray, setPhotoArray] = useState([]);
  const [zoomLevel, setZoomLevel] = useState(8);
  const [delayRender, setDelayRender] = useState(false);
  const [weatherCheckboxStatus, setWeatherCheckboxStatus] = useState({
    waveHeight: 'on',
    windSpeed: 'on',
    webCams: 'on',
    photos: 'on',
    windAnimation: 'on',
  });
  const [weatherUnits, setWeatherUnits] = useState({
    waveHeight: 'feet',
    windSpeed: 'miles',
  });
  const [sidePanel, setSidePanel] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });
  const [viewport, setViewport] = useState({
    latitude: 49.4,
    longitude: -123.4,
    zoom: zoomLevel,
  });
  const [hour, setHour] = useState(0);
  const [currentTime, setCurrentTime] = useState('');
  const [playForecast, setPlayForecast] = useState(false);
  const weatherDataRef = useRef(null); // Ref to store weather data
  const forecastTimes = useRef([]); // Ref to store forecast times
  const intervalRef = useRef(null);
  const [forecastTimeArray, setForecastTimeArray] = useState([])
  let animationFrameId;

  // call python script to convert google files to geojson
  // useEffect(() => {
  //   axios.get('https://python-grib2-geojson-converter-549dbe1b344d.herokuapp.com/weather-data')
    
  // }, [])

  useEffect(() => {
    // Update weatherDataRef whenever weatherData changes
    weatherDataRef.current = weatherData;
  }, [weatherData]);

    const findClosestForecastIndex = (filesArray) => {
      // Step 1: Get the current PST time
      const currentPstDate = new Date();
      
      // Step 2: Convert PST to UTC
      const utcOffset = 7 * 60 * 60 * 1000; // 8 hours in milliseconds
      const currentUtcDate = new Date(currentPstDate.getTime() + utcOffset);
    
      // Step 3: Iterate through the array to find the closest forecast hour
      let closestIndex = 0;
      let smallestDifference = Infinity;
    
      filesArray.forEach((file, index) => {
        const fileName = file.filename;
    
        // Extract the date and time from the filename
        const dateStr = fileName.split('_')[0].split('/')[1]; // "20240820T06"
        const hoursOffsetStr = fileName.split('_').pop().replace('.geojson', ''); // "001"
        const hoursOffset = parseInt(hoursOffsetStr, 10); // Convert to integer
    
        // Parse the extracted date into a Date object (UTC)
        const fileDateUtc = new Date(
          Date.UTC(
            parseInt(dateStr.substring(0, 4)), // Year
            parseInt(dateStr.substring(4, 6)) - 1, // Month (0-based)
            parseInt(dateStr.substring(6, 8)), // Day
            parseInt(dateStr.substring(9, 11)) // Hour
          )
        );
    
        // Add the hours offset to the UTC time
        fileDateUtc.setHours(fileDateUtc.getHours() + hoursOffset);
    
        // Calculate the difference in time
        const timeDifference = Math.abs(currentUtcDate - fileDateUtc);
    
        // Check if this is the closest time so far
        if (timeDifference < smallestDifference) {
          smallestDifference = timeDifference;
          closestIndex = index;
        }
      });
    
      // Step 4: Return the index of the closest forecast hour
      console.log("hour index: ", closestIndex)
      return closestIndex;
    };

    useEffect(() => {
      if(originalWeatherData) {
        const hourIndex = findClosestForecastIndex(originalWeatherData)   
        setHour(hourIndex-7)   
      }

    }, [originalWeatherData])

    const convertUtcToPst = (fileName) => {
      // Extract the date and time from the filename
      const dateStr = fileName.split('_')[0].split('/')[1]; // "20240816T00"
      const hoursOffsetStr = fileName.split('_').pop().replace('.geojson', ''); // "001"
      const hoursOffset = parseInt(hoursOffsetStr, 10); // Convert to integer
    
      // Parse the extracted date into a Date object (UTC)
      const reportDateUtc = new Date(
        Date.UTC(
          parseInt(dateStr.substring(0, 4)), // Year
          parseInt(dateStr.substring(4, 6)) - 1, // Month (0-based)
          parseInt(dateStr.substring(6, 8)), // Day
          parseInt(dateStr.substring(9, 11)) + hoursOffset // Hour adjusted by offset
        )
      );
    
      // Format the date directly to PST (UTC-7)
      const formattedDate = reportDateUtc.toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles', // Specify PST timezone
        weekday: 'short', // "Mon"
        hour: 'numeric', // "4"
        minute: 'numeric', // "00"
        hour12: true // "pm"
      }).replace(':00', ''); // Remove ":00" if minutes are zero
    
      return formattedDate;
    };    

  useEffect(() => {
    if(weatherData) {

      const forecastTime = weatherData.map((file) => {
        if(file.filename) {
        return convertUtcToPst(file.filename)
        }
      })

      setForecastTimeArray(forecastTime)
    
      console.log("formatted time array: ", forecastTime)
    }
  }, [weatherData])

  const handleSliderChange = (event) => {
    const selectedHour = parseInt(event.target.value, 10);
    setHour(selectedHour);
    setCurrentTime(forecastTimes.current[selectedHour]); // Update the current time display
    updateWeatherData(selectedHour);
  };

  // need to restart the play with the last index (currently it restarts at 0)
  const handlePlayButtonClick = () => {
    setPlayForecast(true);
    let index = hour || 0;
    intervalRef.current = setInterval(() => {
      updateWeatherData(index);
      index = (index + 1) % weatherData.length;
      // setHour as the index and have it loop back to 0 when it reaches the end
      setHour(index);
      console.log(index)
    }, 1400);
  };

  const handlePauseButtonClick = () => {
    setPlayForecast(false);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const updateWeatherData = (forecast) => {
    if (mapRef.current && weatherDataRef.current) {
      mapRef.current.getSource('wind-data').setData(weatherDataRef.current[forecast].content);
      mapRef.current.getSource('weather-data').setData(weatherDataRef.current[forecast].content);
      animateDot(forecast);
    }
  };

  const animateDot = (index) => {
    const map = mapRef.current;
  
    if (!map || !map.getSource('wind-data')) {
      return; // Exit if map or source is not available
    }
  
    const maxDistance = 0.15; // Max distance before resetting to original position
    let speedFactor = 0.00015; // Adjust speed factor as needed
  
    const features = weatherDataRef.current[index].content.features.map((feature) => {
      const originalLat = feature.properties.originalLat;
      const originalLng = feature.properties.originalLng;
  
      const { s, d } = feature.properties;
      const speed = s * speedFactor;
      const radians = d * (Math.PI / 180);
      const dx = speed * Math.cos(radians);
      const dy = speed * Math.sin(radians);
  
      feature.geometry.coordinates[0] += dx;
      feature.geometry.coordinates[1] += dy;
  
      const distance = Math.sqrt(
        Math.pow(feature.geometry.coordinates[0] - originalLat, 2) +
        Math.pow(feature.geometry.coordinates[1] - originalLng, 2)
      );
  
      if (distance > maxDistance) {
        feature.geometry.coordinates = [
          originalLat + (Math.random() * 0.1),
          originalLng + (Math.random() * 0.1)
        ];
      }
  
      return feature;
    });
  
    // Update the source with new coordinates
    if (map.getSource('wind-data')) {
      map.getSource('wind-data').setData({
        type: 'FeatureCollection',
        features: features
      });
    }
  
    // Schedule the next frame
    animationFrameId = requestAnimationFrame(() => animateDot(index));
  };
  
  // Cancel animation on unmount or when navigating away
  useEffect(() => {
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, []);
  
  useEffect(() => {
    
    const map = mapRef.current;
  
    if (map && weatherData) {

      setShowLoader(false)

      const addSourcesAndLayers = () => {
        if (!map.getSource('weather-data')) {
          map.addSource('weather-data', {
            type: 'geojson',
            data: weatherData[0].content
          });
        }
  
        if (!map.getSource('wind-data')) {
          map.addSource('wind-data', {
            type: 'geojson',
            data: weatherData[0].content
          });
        }

        if (!map.getLayer('weatherDataLayer')) {
          map.addLayer({
            id: 'weatherDataLayer',
            type: 'circle',
            source: 'weather-data',
            paint: {
              'circle-radius': [
                'interpolate',
                ['exponential', 2],
                ['zoom'],
                4.5, 15,
                5, 22,
                5.5, 26,
                
                6, 32,
                6.25, 36,
                6.5, 42,
                6.75, 49,
    
                7, 53,
                7.25, 58,
                7.5, 63,
                7.75, 68,
    
                8, 85,
                8.25, 100,
                8.5, 115,
                8.75, 145,
    
                9, 175,
                9.25, 205,
                9.5, 235,
                9.75, 275,
    
                10, 305,
                10.25, 335,
                10.5, 365,
                10.75, 375,
    
                11, 400,
                11.25, 435,
                11.5, 460,
              ],
              'circle-color': [
                  'interpolate',
                  ['linear'],
                  ['get', 's'],
                  0, 'rgba(98, 0, 255, 0.1)',    // Color for low wind speed
                  2.57, 'rgba(0, 0, 255, 0.1)',   // Color for moderate wind speed
                  5.1, 'rgba(0, 246, 0, 0.15)',   // Color for higher wind speed
                  7.7, 'rgba(255, 250, 0, 0.15)',   // Color for even higher wind speed
                  10.5, 'rgba(255, 159, 0, 0.15)',   // Color for very high wind speed
                  12.86, 'rgba(255, 0, 0, 0.15)',   // Color for very high wind speed
                  // 15, 'rgba(0,0,0.15)'    // Color for extremely high wind speed
              ],
              'circle-blur': 1 // Add blur to blend circles
            }
          });
        }
  
        if (!map.getLayer('windLayer')) {
          map.addLayer({
            id: 'windLayer',
            type: 'circle',
            source: 'wind-data',
            paint: {
              'circle-radius': [
                'interpolate',
                ['exponential', 2],
                ['zoom'],
                4.5, .5,
                8, 1.5,
              ],
                'circle-color': 'white',
                'circle-blur': .5 // Add blur to blend circles
            }
            });
        }

         // Add hover event listener to update testData state
        map.on('mousemove', 'weatherDataLayer', (e) => {
          if (e.features && e.features.length > 0) {
            const windSpeedData = e.features[0].properties;
            console.log("wind speed data: ", windSpeedData)
            setTestData(windSpeedData);
          }
        });

        animateDot(0);

        // Clean up hover event listener on component unmount
        return () => {
          map.off('mousemove', 'weatherDataLayer');
        };
  
      };
  
      // Ensure the map is fully loaded before adding sources/layers
      if (!map.isStyleLoaded()) {
        map.on('load', addSourcesAndLayers);
      } else {
        addSourcesAndLayers();
      }
  
      // Cleanup function to remove layers and sources
      return () => {
        if (map.getLayer('weatherDataLayer')) {
          map.removeLayer('weatherDataLayer');
        }
        if (map.getLayer('windLayer')) {
          map.removeLayer('windLayer');
        }
        if (map.getSource('weather-data')) {
          map.removeSource('weather-data');
        }
        if (map.getSource('wind-data')) {
          map.removeSource('wind-data');
        }
      };
    }
  }, [weatherData]);

  useEffect(() => {

    setTimeout(() => {
      

    const map = mapRef.current;

    if (!map) {
      console.error("Map instance is not available yet.");
      return;
    }

    console.log("map and weatherdata ", map, weatherData)
  
    if (map && weatherData) {
      const addSourcesAndLayers = () => {
        if (!map.getSource('weather-data')) {
          map.addSource('weather-data', {
            type: 'geojson',
            data: weatherData[0].content
          });
        }
  
        if (!map.getSource('wind-data')) {
          map.addSource('wind-data', {
            type: 'geojson',
            data: weatherData[0].content
          });
        }

        if (!map.getLayer('weatherDataLayer')) {
          map.addLayer({
            id: 'weatherDataLayer',
            type: 'circle',
            source: 'weather-data',
            paint: {
              'circle-radius': [
                'interpolate',
                ['exponential', 2],
                ['zoom'],
                4.5, 15,
                5, 22,
                5.5, 26,
                
                6, 32,
                6.25, 36,
                6.5, 42,
                6.75, 49,
    
                7, 53,
                7.25, 58,
                7.5, 63,
                7.75, 68,
    
                8, 85,
                8.25, 100,
                8.5, 115,
                8.75, 145,
    
                9, 175,
                9.25, 205,
                9.5, 235,
                9.75, 275,
    
                10, 305,
                10.25, 335,
                10.5, 365,
                10.75, 375,
    
                11, 400,
                11.25, 435,
                11.5, 460,
              ],
              'circle-color': [
                  'interpolate',
                  ['linear'],
                  ['get', 's'],
                  0, 'rgba(98, 0, 255, 0.07)',    // Color for low wind speed
                  2.57, 'rgba(0, 0, 255, 0.07)',   // Color for moderate wind speed
                  5.1, 'rgba(0, 246, 0, 0.15)',   // Color for higher wind speed
                  7.7, 'rgba(255, 250, 0, 0.15)',   // Color for even higher wind speed
                  10.5, 'rgba(255, 159, 0, 0.15)',   // Color for very high wind speed
                  12.86, 'rgba(255, 0, 0, 0.15)',   // Color for very high wind speed
                  // 15, 'rgba(0,0,0.15)'    // Color for extremely high wind speed
              ],
              'circle-blur': 1 // Add blur to blend circles
            }
          });
        }
  
        if (!map.getLayer('windLayer')) {
          map.addLayer({
            id: 'windLayer',
            type: 'circle',
            source: 'wind-data',
            paint: {
              'circle-radius': [
                'interpolate',
                ['exponential', 2],
                ['zoom'],
                4.5, .5,
                8, 1.5,
              ],
                'circle-color': 'white',
                'circle-blur': .5 // Add blur to blend circles
            }
            });
        }
  
        animateDot(0);
      };
  
      // Ensure the map is fully loaded before adding sources/layers
      if (!map.isStyleLoaded()) {
        map.on('load', addSourcesAndLayers);
      } else {
        addSourcesAndLayers();
      }
  
      // Cleanup function to remove layers and sources
      return () => {
        if (map.getLayer('weatherDataLayer')) {
          map.removeLayer('weatherDataLayer');
        }
        if (map.getLayer('windLayer')) {
          map.removeLayer('windLayer');
        }
        if (map.getSource('weather-data')) {
          map.removeSource('weather-data');
        }
        if (map.getSource('wind-data')) {
          map.removeSource('wind-data');
        }
      };
    }

  }, 1000);

  }, []);
  
  // distance between wind dots
  const largeRange = 0.5;
  const mediumRange = 0.3;
  const smallRange = 0.2;

  const windDirectionToAngle = (windDirection) => {
    const directions = {
      N: 270,
      NNE: 248,
      NE: 225,
      ENE: 208,
      E: 180,
      ESE: 158,
      SE: 135,
      SSE: 112,
      S: 90,
      SSW: 23,
      SW: 45,
      NW: 315,
      NNW: 293,
      WSW: 23,
      W: 0,
      WNW: 340,
      calm: 0,
    };

    const windAngle = directions[windDirection] !== undefined ? directions[windDirection] * (Math.PI / 180) : null;
    if (windAngle > 360) {
      return windAngle - 360;
    }

    return windAngle;
  };

  // Configuration array for different point sets
  const pointSets = [
    {
      id: 'ferryTerminal',
      initialCoordinates: { x: -123.281, y: 48.9514, range: mediumRange },
      color: weatherStation.ferryTerminal.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.ferryTerminal.windDirection[0]) 
    },
    {
      id: 'howeSound',
      initialCoordinates: { x: -123.4362, y: 49.39123, range: mediumRange },
      color: weatherStation.howeSound.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.howeSound.windDirection[0])

  },
    {
      id: 'pointAtkinson',
      initialCoordinates: { x: -123.26, y: 49.2843, range: mediumRange },
      color: weatherStation.pointAtkinson.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.pointAtkinson.windDirection[0])
    },
  //   // wave buoy
    {
      id: 'westSOG',
      initialCoordinates: { x: -123.5437, y: 48.8464, range: mediumRange },
      color: waveBuoy.georgiaStrait.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(waveBuoy.georgiaStrait.windDirection[0])
    },
    {
      id: 'sandheads',
      initialCoordinates: { x: -123.39, y: 49.0244, range: mediumRange },
      color: weatherStation.sandheads.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.sandheads.windDirection[0])
    },
  //   // wave buoy
    {
      id: 'englishBay',
      initialCoordinates: { x: -123.467, y: 49.244, range: mediumRange },
      color: waveBuoy.englishBay.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(waveBuoy.englishBay.windDirection[0]) 
    },
  // // wave buoy
    {
      id: 'halibutBank',
      initialCoordinates: { x: -123.789, y: 49.112, range: mediumRange },
      color: waveBuoy.halibutBank.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(waveBuoy.halibutBank.windDirection[0])
    },
    {
      id: 'entranceIsland',
      initialCoordinates: { x: -123.9069, y: 49.1312, range: mediumRange },
      color: weatherStation.entranceIsland.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.entranceIsland.windDirection[0])
    },
    {
      id: 'ballenasIslands',
      initialCoordinates: { x: -124.132, y: 49.292, range: mediumRange },
      color: weatherStation.ballenasIslands.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.ballenasIslands.windDirection[0])
    },
    {
      id: 'sechelt',
      initialCoordinates: { x: -123.779, y: 49.345, range: mediumRange },
      color: weatherStation.sechelt.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.sechelt.windDirection[0])    
    },
    {
      id: 'sisterIslets',
      initialCoordinates: { x: -124.559, y: 49.328, range: mediumRange },
      color: weatherStation.sisterIslets.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.sisterIslets.windDirection[0])
    },
    {
      id: 'sisterIsletsNorth',
      initialCoordinates: { x: -124.768, y: 49.489, range: mediumRange },
      color: weatherStation.sisterIslets.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.sisterIslets.windDirection[0])
    },
    {
      id: 'griefPoint',
      initialCoordinates: { x: -124.7918, y: 49.7138, range: mediumRange },
      color: weatherStation.griefPoint.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.griefPoint.windDirection[0])
    },
   // wave buoy
    {
      id: 'sentryShoal',
      initialCoordinates: { x: -125.032, y: 49.812, range: mediumRange },
      color: waveBuoy.sentryShoal.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(waveBuoy.sentryShoal.windDirection[0])
    },
    {
      id: 'campbellRiverAirport',
      initialCoordinates: { x: -125.226, y: 49.85166, range: mediumRange },
      color: weatherStation.campbellRiverAirport.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.campbellRiverAirport.windDirection[0])   
    },
    {
      id: 'victoriaIntlAirport',
      initialCoordinates: { x: -123.426, y: 48.4924, range: mediumRange },
      color: weatherStation.victoriaIntlAirport.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.victoriaIntlAirport.windDirection[0])   
    },
    {
      id: 'kelpReefs',
      initialCoordinates: { x: -123.259, y: 48.4273, range: mediumRange },
      color: weatherStation.kelpReefs.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.kelpReefs.windDirection[0])   
    },
    {
      id: 'discoveryIsland',
      initialCoordinates: { x: -123.259, y: 48.2773, range: mediumRange },
      color: weatherStation.discoveryIsland.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.discoveryIsland.windDirection[0])   
    },
    {
      id: 'raceRocksLightstation',
      initialCoordinates: { x: -123.53959, y: 48.1273, range: mediumRange },
      color: weatherStation.raceRocksLightstation.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.raceRocksLightstation.windDirection[0])   
    },
    {
      id: 'raceRocksLightstation2',
      initialCoordinates: { x: -123.93959, y: 48.1273, range: mediumRange },
      color: weatherStation.raceRocksLightstation.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.raceRocksLightstation.windDirection[0])   
    },
    {
      id: 'usaStation2',
      initialCoordinates: { x: -123.15959, y: 48.1473, range: mediumRange },
      color: weatherStation.usaStation2.windDirection[0] === 'calm' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.21)',
      angle: windDirectionToAngle(weatherStation.usaStation2.windDirection[0])   
    },
  ];

  const handleMapLoad = useCallback((event) => {
    const map = event.target;
    mapRef.current = map;
  }, []);
  
  const handleMapLayer = () => {

    const map = mapRef.current;

    let visibility = map.getLayoutProperty('weatherDataLayer', 'visibility');

    if (visibility === undefined) {
        // Set the initial visibility to 'none' or 'visible' depending on your default
        visibility = 'visible';
        map.setLayoutProperty('weatherDataLayer', 'visibility', visibility);
    }

    if (visibility === 'visible') {
      map.setLayoutProperty('windLayer', 'visibility', 'none');
      map.setLayoutProperty('weatherDataLayer', 'visibility', 'none');
    } else {
      map.setLayoutProperty('windLayer', 'visibility', 'visible');
      map.setLayoutProperty('weatherDataLayer', 'visibility', 'visible');
  }
}

  useEffect(() => {
    setTimeout(() => {
      setDelayRender(true)
    }, 1500);
  }, []);


  useEffect(() => {

    let retryCount = 0;

    const applySettings = () => {
      const map = mapRef.current;
      if (map) {
        const visibility = map.getLayoutProperty('ferryTerminal', 'visibility');
        // If the layer is not visible, retry after 1 second.. cannot find a callback to check when the layer is fully loaded
        if (!visibility && retryCount < 3) {
          retryCount++;
          setTimeout(applySettings, 1000);  // Retry after 1 second
          return;
        }
        if (visibility) {
          const savedSettings = localStorage.getItem('weatherCheckboxStatus');
          const savedSettingsParsed = JSON.parse(savedSettings);
          if (savedSettingsParsed) {
            if (savedSettingsParsed.windAnimation === 'off') {
              pointSets.forEach((set) => {
                map.setLayoutProperty(set.id, 'visibility', 'none');
                map.setLayoutProperty(`${set.id}-secondary`, 'visibility', 'none');
              });
            }
            // Apply other saved settings as necessary
            setWeatherCheckboxStatus(savedSettingsParsed);
          }
        }
      }
    };

    applySettings();

  }, [pointSets]);

  // toggle map layers and save to local storage
  const handleSettingsCheckBoxChange = (event) => {
    const { name, checked } = event.target;
    if(name === 'windAnimation') handleMapLayer();
    const newSettings = {
      ...weatherCheckboxStatus,
      [name]: checked ? 'on' : 'off',
    }
    setWeatherCheckboxStatus(newSettings);
    localStorage.setItem('weatherCheckboxStatus', JSON.stringify(newSettings));
  };
    
  const handleWeatherClick = (value) => {  
    // Redirect to the '/weatherTable' page and pass the weatherData as state
    navigate('/weatherTable', { state: value});
  };

  const handleFishingReportClick = () => {
    // Redirect to the '/fishingReport' page
    navigate('/FishingReportJune10');
  };

  const handleUnitChange = (event) => {
    const { name, value } = event.target;
    setWeatherUnits((prevUnits) => ({
        ...prevUnits,
        [name]: value,
    }));
  };

  // 
  const setWeatherSettingsOpen = () => {
    setShowSettingsDropdown(!showSettingsDropdown)
  }

  // TODO: Issue where only the most recent photo is displayed.. need all photos to show (if within 6 hours)
  const retrievePhotosWithinTimeRange = async () => {
    const currentTime = new Date();
    const sixHoursAgo = new Date(currentTime.getTime() - 6 * 60 * 60 * 1000); // 6 hours ago
  
    try {
      // Retrieve a list of all files in the Firebase Storage folder
      const files = await listAll(ref(storage, 'uploads'));
  
      // Filter files based on their upload date and time
      const filteredFiles = await Promise.all(files.items.map(async (fileRef) => {
        const metadata = await getMetadata(fileRef);
        const uploadTime = new Date(metadata.timeCreated);
  
        // Check if upload time is within 6 hours of current time
        if (uploadTime >= sixHoursAgo && uploadTime <= currentTime) {
          return {
            name: fileRef.name,
            downloadURL: await getDownloadURL(fileRef),
            metadata: metadata,
          };
        } else {
          return null;
        }
      }));
  
      // Remove null entries and return the filtered files
      return filteredFiles.filter((file) => file !== null);
    } catch (error) {
      console.error('Error retrieving photos:', error);
      return [];
    }
  };

  // Retrieve weather photos from storage
  useEffect(() => {

    retrievePhotosWithinTimeRange()
    .then((photos) => {
      setPhotoArray(photos);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  
    }, []);

  // Set users location to center of map
  useEffect(() => {
    if(userLocation.lat > 0) {
      viewport.latitude = userLocation.lat;
      viewport.longitude = userLocation.lng;
    }
  })

  // Check if user is within ocean polygon to allow a photo upload of weather conditions
  const handleFileChange = (event) => {
    // Check if user is within polygon
    if (userLocation.lat > 0) {
      const isInPolygon = geolib.isPointInPolygon({ latitude: userLocation.lat, longitude: userLocation.lng }, BCPolygon);
      // Check if the user is not inside the polygon and alert the user
      if (!isInPolygon) {
        alert('You must be on the BC coast to upload a photo');
        return;
      }
    }

    const file = event.target.files[0];
    setSelectedFile(file);
  
    // Show preview of the selected image
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };  

  // Handle the weather photo file upload
  const handlePhotoUpload = () => {
    if (selectedFile) {
      setShowLoader(true);
      const imgRef = ref(storage, 'uploads/' + selectedFile.name);
  
      // Upload the file
      uploadBytes(imgRef, selectedFile)
        .then((snapshot) => {
          console.log('File uploaded successfully:', snapshot);
  
          // Get the download URL of the uploaded file
          return getDownloadURL(imgRef);
        })
        .then((downloadURL) => {
          // Update metadata with custom coordinates
          const metadata = {
            contentType: 'image/jpeg', // Set the content type of the file
            customMetadata: {
              latitude: userLocation.lat,
              longitude: userLocation.lng,
            },
          };
  
          // Update metadata of the uploaded file
          return updateMetadata(imgRef, metadata);
        })
        .then(() => {
          console.log('Metadata updated successfully');
          // Reset the selected file and preview image
          setSelectedFile(null);
          setPreviewImage(null);
          setShowLoader(false);
        })
        .catch((error) => {
          console.error('Error uploading file:', error);
        });
    } else {
      console.log('No file selected');
    }
  };

  const getRotationAngle = (windDirection) => {
    const windDirectionMap = {
      'N': require('../../images/windArrows/windN.png'),
      'NNE': require('../../images/windArrows/windNE.png'),
      'NE': require('../../images/windArrows/windNE.png'),
      'ENE': require('../../images/windArrows/windNE.png'),
      'E': require('../../images/windArrows/windE.png'),
      'ESE': require('../../images/windArrows/windSE.png'),
      'SE': require('../../images/windArrows/windSE.png'),
      'SSE': require('../../images/windArrows/windSE.png'),
      'S': require('../../images/windArrows/windS.png'),
      'SSW': require('../../images/windArrows/windSW.png'),
      'SW': require('../../images/windArrows/windSW.png'),
      'WSW': require('../../images/windArrows/windSW.png'),
      'W': require('../../images/windArrows/windW.png'),
      'WNW': require('../../images/windArrows/windNW.png'),
      'NW': require('../../images/windArrows/windNW.png'),
      'NNW': require('../../images/windArrows/windNW.png'),
    };

    // Default rotation angle for unknown wind directions
    const defaultAngle = 0;

    // Convert wind direction to uppercase for case-insensitivity
    if(windDirection === 'calm') return defaultAngle;
    if(windDirection === 'N/A') return defaultAngle;
    if(windDirection === 'Unknown') return defaultAngle;
    if(windDirection === '-') return defaultAngle;
    const normalizedWindDirection = windDirection;
  
    // Get the rotation angle from the map or use the default angle
    return windDirectionMap[normalizedWindDirection] || defaultAngle;
  };  

  const analyzeWaveHeights = (waveHeights) => {

    const len = waveHeights.length;
    if (len < 2) return "Not enough data to analyze wave heights.";

    const latest = waveHeights[0];
    const secondLatest = waveHeights[1];
    const thirdLatest = waveHeights[2];
      
    if (len >= 3 && latest > secondLatest && secondLatest > thirdLatest) {
      return "Waves have been consistently building over the last 3 hours.";
    }
    if (len >= 2 && latest > secondLatest && secondLatest > thirdLatest) {
      return "Waves have been building over the past 2 hours.";
    }
    if (latest > secondLatest) {
      return "Waves are starting to increase.";
    }
    if (len >= 2 && Math.abs(latest - secondLatest) / secondLatest <= 0.05) {
      return "Waves have remained consistent.";
    }
    if (len >= 3 && latest < secondLatest && secondLatest < thirdLatest) {
      return "Waves have been consistently decreasing over the last 3 hours.";
    }
    if (len >= 2 && latest < secondLatest && secondLatest < thirdLatest) {
      return "Waves have been decreasing over the past 2 hours.";
    }
    if (latest < secondLatest) {
      return "Waves are starting to decrease.";
    }
  
    return "Wave heights are fluctuating.";
  };

  const analyzeWindSpeeds = (windSpeeds) => {
    const len = windSpeeds.length;
    if (len < 2) return "Not enough data to analyze wind speed.";
  
    const latest = parseFloat(windSpeeds[0]);
    const secondLatest = parseFloat(windSpeeds[1]);
    const thirdLatest = parseFloat(windSpeeds[2]);
  
    if (len >= 3 && latest > secondLatest && secondLatest > thirdLatest) {
      return "The wind has been consistently building over the last 3 hours.";
    }
    if (len >= 2 && latest > secondLatest && secondLatest > thirdLatest) {
      return "The wind has been building over the past 2 hours.";
    }
    if (latest > secondLatest) {
      return "The wind is starting to increase.";
    }
    if (len >= 2 && Math.abs(latest - secondLatest) / secondLatest <= 0.05) {
      return "The wind has remained consistent.";
    }
    if (len >= 3 && latest < secondLatest && secondLatest < thirdLatest) {
      return "The wind has been consistently decreasing over the last 3 hours.";
    }
    if (len >= 2 && latest < secondLatest && secondLatest < thirdLatest) {
      return "The wind has been decreasing over the past 2 hours.";
    }
    if (latest < secondLatest) {
      return "The wind is starting to decrease.";
    }
  
    return "The wind has been fluctuating.";
  };

  const categorizeDirection = (direction) => {
    switch (direction) {
      case 'N':
        return 'N';
      case 'NNE':
      case 'NE':
      case 'ENE':
        return 'NE';
      case 'E':
        return 'E';
      case 'ESE':
      case 'SE':
      case 'SSE':
        return 'SE';
      case 'S':
        return 'S';
      case 'SSW':
      case 'SW':
      case 'WSW':
        return 'SW';
      case 'W':
        return 'W';
      case 'WNW':
      case 'NW':
      case 'NNW':
        return 'NW';
      default:
        return 'Unknown';
    }
  };

  const analyzeWindDirections = (windDirections) => {
    const len = windDirections.length;
    if (len < 6) return "Not enough data available.";
  
    const lastSixDirections =[
      windDirections[0],
      windDirections[1],
      windDirections[2],
      windDirections[3],
      windDirections[4],
      windDirections[5],
    ]
    const categorizedDirections = lastSixDirections.map(categorizeDirection);
  
    const directionCounts = categorizedDirections.reduce((acc, dir) => {
      acc[dir] = (acc[dir] || 0) + 1;
      return acc;
    }, {});
  
    const predominantDirection = Object.keys(directionCounts).reduce((a, b) =>
      directionCounts[a] > directionCounts[b] ? a : b
    );
  
    return `Wind has typically been coming from the ${predominantDirection}.`;
  };

  const generateChartData = (selectedMarker) => {

    const updateTimes = selectedMarker.updateTime;

    const formattedTimeArray = []

    updateTimes.forEach((data) => {
      const date = new Date(data);
      const formattedTime = date.toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          timeZone: 'America/Los_Angeles',
      });

      formattedTimeArray.push(formattedTime);

    });

    // Reverse the array to display the most recent data first
    formattedTimeArray.reverse();

    // Reverse the order of the data array (if needed)
    const reversedData = selectedMarker.waveHeight
    ? [...selectedMarker.waveHeight].reverse()
    : [...selectedMarker.windSpeed].reverse();
  
      const data = {
        labels: formattedTimeArray,
        datasets: [
          {
            label: selectedMarker.waveHeight ? 'Wave Height' :'Wind Speed',
            data: reversedData,
            fill: true,
            tension: 0.4,
            backgroundColor: 'rgba(255,165,0,.6)',
            borderColor: 'rgba(0,0,255,.35)',
          },
        ],
      };

      return data

  };

  return (
  <ReactMapGl
    initialViewState={viewport}
    mapboxAccessToken={MAPBOX_API_KEY}
    maxZoom={15}
    minZoom={4.5}
    onZoom={(e) => { 
      setZoomLevel(e.viewState.zoom)
      console.log(e.viewState.zoom)
      }}
    style={{width: '100vw', height: '100vh'}}
    mapStyle="mapbox://styles/jcon12/clx8bi8se007s01mwfnl36y68"
    transitionDuration={200}
    ref={mapRef}
    onLoad={handleMapLoad}
  >
    {testData?.s &&
      <WindSpeedWindow windSpeed={Math.round(testData?.s * 2.237)} />
    }
    {showLoader && 
      <div className='loading-spinner'>
        <RotatingLines
            visible={true}
            strokeColor='grey'
            height="56"
            width="56"
            strokeWidth="4"
            animationDuration="0.75"
        />
      </div>
    }
    {/* {delayRender && <DropDownMsg onClick={handleFishingReportClick} className='animate__bounceIn' msg='June 10th Fishing Report' />} */}
    <button className='animate__bounceIn weather-slide-btn' onClick={() => setSidePanel({ isPaneOpen: true })}>
      Weather Summary
    </button>
    <SlidingPane
      overlayClassName="weather-slider"
      isOpen={sidePanel.isPaneOpen}
      title="Wave Buoy Weather Summary"
      // subtitle="Optional subtitle."
      onRequestClose={() => {
        // triggered on "<" on left top click or on outside click
        setSidePanel({ isPaneOpen: false });
      }}
    >      
      {waveBuoy && Object.keys(waveBuoy).map(buoyKey => {
        return(

          <div className="weather-forecast-container">
            <div>
              <h3 className='mobile-wrap'>{waveBuoy[buoyKey].name}</h3>
              <p>{analyzeWaveHeights(waveBuoy[buoyKey].waveHeight)}</p>
              <p>{analyzeWindSpeeds(waveBuoy[buoyKey].windSpeed)}</p>
              <p>{analyzeWindDirections(waveBuoy[buoyKey].windDirection)}</p>
            </div>
          </div>
        )
      })
      }  
    </SlidingPane>
    {mobile ? <BottomNav /> : <SidebarPro />} 
    {mobile && !previewImage &&
        <label onChange={handleFileChange} for="photo" class="weather-photo-upload">
          <CiCamera size={30} />
          <input
            type="file"
            accept="image/*"
            id="photo" // Add an id to the file input
            name='photo'
            onChange={handleFileChange}
          />
        </label>
      }
    {previewImage && <PhotoPreview showLoader={showLoader} setPreviewImage={setPreviewImage} previewImage={previewImage} handleSubmit={handlePhotoUpload} />}
    <WeatherSettingsBtn setWeatherSettingsOpen={setWeatherSettingsOpen} />
    {showSettingsDropdown &&
      <WeatherMapUnits setShowSettingsDropdown={setShowSettingsDropdown} showSettingsDropdown={showSettingsDropdown} handleUnitChange={handleUnitChange} weatherUnits={weatherUnits} />
    }
    <WeatherLayerCheckBox weatherCheckboxStatus={weatherCheckboxStatus} handleSettingsCheckBoxChange={handleSettingsCheckBoxChange} />
    {userLocation.lat !== 0 && userLocation.lng !== 0 &&
      <Marker
        latitude={userLocation.lat}
        longitude={userLocation.lng}
      >
        <img src={require('../../images/userDot.png')} alt="user location" style={{width: '16px', height: '16px'}} />
      </Marker>          
    }
    {weatherCheckboxStatus.windSpeed === 'on' &&
      Object.values(weatherStation).map((station, index) => {

        let showBasedOnZoom = true

        if(zoomLevel < 5) {
          showBasedOnZoom = false
        } else if(station.zoomLevel === 'off' && zoomLevel < 6) {
          showBasedOnZoom = false
        } else if(station.zoomLevel === 'low' && zoomLevel < 7.3) {
          showBasedOnZoom = false
        } else if(station.zoomLevel === 'medium' && zoomLevel < 7.7) {
          showBasedOnZoom = false
        } else if(station.zoomLevel === 'high' && zoomLevel < 9) {
          showBasedOnZoom = false
        }

        if(showBasedOnZoom === false) {
          return null
        }

        const windSpeed = station.windSpeed[0]
        const windDirection = station.windDirection[0]
        const windDirectionAngle = getRotationAngle(windDirection);

        if(isNaN(windSpeed) && windSpeed !== 'calm') {
          return null;
        }

        return (
          <Marker
            key={index}
            latitude={station.coordinates.lat}
            longitude={station.coordinates.lng}
          >
            <div 
              onClick={() => setSelectedMarker(station)}
              className={station.windSpeed[0].toString().length < 3 ? 'wind-speed-label' : 'wind-speed-label-small-text'}
            >
              {convertUnitsUserSettings(station.windSpeed[0].toString(), weatherUnits.windSpeed)}
            </div>
            {station.windSpeed[0].toString() === 'calm' ? '' : 
            <img 
              className='weather-arrow-icon' 
              onClick={() => setSelectedMarker(station)}
              src={windSpeed > 0 ? windDirectionAngle : require('../../images/windArrows/windN.png')} width={22} height={22}                 
            />}
          </Marker>
        )
      })
    }    
    {selectedMarker && (
      <Popup 
        longitude={selectedMarker.coordinates.lng} 
        latitude={selectedMarker.coordinates.lat}
        anchor="bottom"
        closeOnClick={false} 
        onClose={() => setSelectedMarker(null)}
        >
        {selectedMarker.type === 'weatherStation' ? 
        <div className='weather-popup'>
          <div
            onClick={() => handleWeatherClick('wind')}
          >
            <FiWind className='weather-icon' size={25} color='black' />
          </div>
          <h3>{selectedMarker.name}</h3>
          <p>Wind Speed: {convertUnitsUserSettings(selectedMarker.windSpeed[0], weatherUnits.windSpeed)}</p>
          <p>Wind Direction: {selectedMarker.windDirection[0]}</p>
          <p>Update Time: {transformTime(selectedMarker.updateTime[0])}</p>
          <div className='line-chart-container'>
            <Line data={generateChartData(selectedMarker)} options={{
              scales: {
                y: {
                  beginAtZero: true,
                  suggestedMax: selectedMarker.windSpeed[0] + 3,
                }, 
                x: {
                  display: false,
                }
              },
              plugins: {
              legend: {
                display: false
                }
              },
            }} />
          </div>
        </div> : 
        <div className='weather-popup'>
          <div
            onClick={() => handleWeatherClick('wave')}
          >
            <GiBigWave className='weather-icon' size={25} color='black' />
          </div>
          <h3>{selectedMarker.name}</h3>
          <p>Wave Height: {convertUnitsUserSettings(selectedMarker.waveHeight[0], weatherUnits.waveHeight) + ` ` + weatherUnits.waveHeight}</p>                  
          <p>Wind Speed: {convertUnitsUserSettings(selectedMarker.windSpeed[0], weatherUnits.windSpeed) + ` ` + weatherUnits.windSpeed}</p>
          <p>Wind Direction: {selectedMarker.windDirection[0]}</p>
          <p>Update Time: {transformTime(selectedMarker.updateTime[0])}</p>
          <div className='line-chart-container'>
            <Line data={generateChartData(selectedMarker)} options={{
              scales: {
                y: {
                  beginAtZero: true,
                  suggestedMax: selectedMarker.waveHeight[0] + 3,
                }, 
                x: {
                  display: false,
                }
              },
              plugins: {
              legend: {
                display: false
                }
              },
            }} />
          </div>
        </div>}
      </Popup>)}
      {weatherCheckboxStatus.waveHeight === 'on' &&
        Object.values(waveBuoy).map((buoy, index) => {

        if (isNaN(buoy.waveHeight[0]) && buoy.waveHeight[0] !== 'calm') {
          return null;
        }

        const waveHeight = buoy.waveHeight[0]
        const waveHeight2 = buoy.waveHeight[1]
        const waveHeightFixed = weatherUnits.waveHeight === 'feet' ? 
        parseFloat(waveHeight) < 10 ? 
        parseFloat(waveHeight).toFixed(1) : 
        parseFloat(waveHeight).toFixed(0) : 
        parseFloat(waveHeight).toFixed(1) ? 
        parseFloat(waveHeight).toFixed(2) :
        parseFloat(waveHeight).toFixed(1);

        const waveHeightDirection = waveHeight > waveHeight2 ? require('../../images/windArrows/waveUp.png') : require('../../images/windArrows/waveDown.png');

          return (
            <Marker
              key={index}
              latitude={buoy.coordinates.lat}
              longitude={buoy.coordinates.lng}
            >
              <div 
                className='wave-height-label'
                onClick={() => setSelectedMarker(buoy)}
              >
                {convertUnitsUserSettings(buoy.waveHeight[0], weatherUnits.waveHeight)}
              </div>
              <img 
                className='weather-arrow-icon' 
                onClick={() => setSelectedMarker(buoy)}
                src={waveHeightDirection} width={14} height={14}                 
              />
            </Marker>
          )
      })
    }
    {weatherCheckboxStatus.webCams === 'on' &&
      newWebCamArray.map((webcam, index) => {

        let showBasedOnZoom = true

        if(zoomLevel < 5) {
          showBasedOnZoom = false
        } else if(zoomLevel < 6.3 && webcam.zoomLevel !== 'off') {
          showBasedOnZoom = false
        } else if(zoomLevel < 7.6 && webcam.zoomLevel === 'low') {
          showBasedOnZoom = false
        }

        if(showBasedOnZoom === false) {
          return null
        }

        return (

        <Marker
          key={index}
          latitude={webcam.coordinates.lat}
          longitude={webcam.coordinates.lng}
          onClick={() => setSelectedWebcam(webcam)}
        >
          <img src={require('../../images/webcamPurple.png')} alt="webcam" style={{width: '16px', height: '16px'}} />
        </Marker>
          )
        }
      )
    }
    {selectedWebcam && 
      <div className={selectedWebcam.className[0]}>
        <SimpleCloseBtn onClick={() => setSelectedWebcam(null)} />
        {selectedWebcam.img ? 
        <img src={selectedWebcam.url} alt="Webcam" className={selectedWebcam.className[3]} />
        :
        <iframe
            title="Webcam Viewer"
            className={selectedWebcam.className[1]}
            src={selectedWebcam.url}
            width="100%"
            height="100%"
            scrolling='no'
        />
        }
        <div className={selectedWebcam.className[2]}></div>
    </div>
    }  
    {weatherCheckboxStatus.photos === 'on' &&  
      photoArray.map((photo, index) => {
        return (
          <Marker
            key={index}
            latitude={parseFloat(photo.metadata.customMetadata.latitude)}
            longitude={parseFloat(photo.metadata.customMetadata.longitude)}
            onClick={() => {
              setSelectedPhoto(photo);
            }}
          >
            <img src={photo.downloadURL} alt="weather image" style={{width: '16px', height: '16px'}} />
          </Marker>
        );              
      })
    }
    {selectedPhoto && !showFullSizePhoto &&
    <Popup
      longitude={selectedPhoto.metadata.customMetadata.longitude} 
      latitude={selectedPhoto.metadata.customMetadata.latitude}
      anchor="bottom"
      closeOnClick={false} 
      onClose={() => {
        setSelectedPhoto(null);
      }}
    >
      <div style={{cursor: 'pointer'}} onClick={() => setShowFullSizePhoto(true)}> 
        <img src={selectedPhoto.downloadURL} alt="Photo" style={{ maxWidth: '100%', maxHeight: '400px' }} />
        <h4 style={{textAlign: 'center', margin: 3, padding: 0}}>{transformTime(selectedPhoto.metadata.timeCreated)}</h4>
      </div>
    </Popup>}
    {selectedPhoto && showFullSizePhoto &&
    <ModalCustom 
      title='Weather Station Information'
      onClick={
        () => {
          setShowFullSizePhoto(false)
          setSelectedPhoto(null)
        }}
    >
        <img src={selectedPhoto.downloadURL} altFaPauseCircle="Photo" style={{ marginTop: -20, maxWidth: '100%', maxHeight: '100%' }} />
    </ModalCustom>
    }
    {forecastTimeArray.length > 0 &&
    <>
      <div className='forecast-hour'>
        <p>{forecastTimeArray[hour]}</p>
      </div>
      <ForecastSlider 
        forecastTimeArray={forecastTimeArray} 
        currentTime={currentTime} 
        playForecast={playForecast} 
        handlePlayButtonClick={handlePlayButtonClick} 
        handlePauseButtonClick={handlePauseButtonClick} 
        handleSliderChange={handleSliderChange} 
        forecastTimes={forecastTimes} 
        hour={hour}           
      />
    </>
    }
    {!mobile && <WeatherLegendBar />}
    <WindSpeedChart />
  </ReactMapGl>
  )
}

export default WeatherMapBox;