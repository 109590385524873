import { FaAnchor, FaWind, FaCampground, FaHiking, FaUmbrellaBeach, FaExclamationTriangle, FaUsers, FaInfoCircle } from 'react-icons/fa'; // Icons for features
import { transformKey } from '../../functions/transformKey';

const DestinationsDockPopupContent = ({ selectedDock }) => {
    
      
    return (
        <div style={{ width: '200px' }}>
        <h3>{selectedDock.name}</h3>

            {/* Features */}
            <div><FaUmbrellaBeach /> <strong>Beaches:</strong> {selectedDock.features.beaches ? 'Yes' : 'No'}</div>
            <div><FaHiking /> <strong>Hiking:</strong> {selectedDock.features.hiking ? 'Yes' : 'No'}</div>
            <div><FaCampground /> <strong>Camping:</strong> {selectedDock.features.camping ? 'Yes' : 'No'}</div>

            {/* Warnings */}
            {selectedDock.warnings && (
                <div><FaExclamationTriangle /> <strong>Warning:</strong> {selectedDock.warnings}</div>
            )}

            {/* Boat Capacity & Popularity */}
            <div><FaUsers /> <strong>Boat Capacity:</strong> {transformKey(selectedDock.boatCapacity) || 'Unknown'}</div>
            <div><FaUsers /> <strong>Boat Stalls:</strong> {selectedDock.boatSpots || 'Unknown'}</div>
            <div><FaUsers /> <strong>Popularity:</strong> {transformKey(selectedDock.popularity) || 'Unknown'}</div>

            {/* Score */}
            <div><FaInfoCircle /> <strong>Score:</strong> {selectedDock.score}/100</div>
        
        </div>
    );
};

export default DestinationsDockPopupContent;
